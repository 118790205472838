
import Vue from "vue"

export default Vue.extend({
  props: {
    to: {
      type: [String, Object] as VuePropType<string | object | undefined>,
      default: undefined,
    },

    href: {
      type: String as VuePropType<string | undefined>,
      default: undefined,
    },

    text: { type: String, required: true },
    type: { type: String as VuePropType<"btn" | "link">, default: "btn" },
  },

  computed: {
    is (): string {
      if (this.href) return "a"
      if (this.to) return "nuxt-link"
      return "span"
    },
  },
})
