export function getBeforeAfter (
  beforeYear?: number, afterYear?: number,
): Partial<Record<string, number>> {
  let beforeTs: number | undefined
  let afterTs: number | undefined

  if (beforeYear) {
    const beforeDate = new Date(beforeYear, 0, 1)
    beforeTs =
    beforeDate.getTime() - beforeDate.getTimezoneOffset() * 60000
  }

  if (afterYear) {
    const afterDate = new Date(afterYear, 0, 1)
    afterTs =
    afterDate.getTime() - afterDate.getTimezoneOffset() * 60000
  }

  return {
    "filter[before]": beforeTs,
    "filter[after]": afterTs,
  }
}
